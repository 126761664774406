.container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;  
  gap: 3rem;
}

.container > h1 {
  width: min(100%, 40rem);
  margin: 0 auto;
  text-align: center;

  font-size: min(4rem, 10vw);
  font-weight: normal;
}

.link {
  width: min(80%, 40rem);
}

.image {
  width: 100%;
  height: auto;
  box-shadow: 0 0 2rem rgba(0, 0, 0, 0.15);
  margin-bottom: 2rem;
}