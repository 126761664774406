.border {
  pointer-events: none;
  margin: 0 7vw;
  border-top: solid 1px silver;
  padding-bottom: 2rem;
  background-color: white;
}

.components {
  display: flex;
  flex-direction: column;
  padding-top: 4rem;
  gap: 13rem;
  background-color: white;
}