.container {
  --padding-h: 16vw;
  padding: 0 var(--padding-h);
  background-color: white;
  display: flex;
  flex-direction: column;
  gap: 3rem;
}

.container > h1 {
  width: min(100%, 40rem);
  margin: 0 auto;
  text-align: center;

  font-size: min(4rem, 10vw);
  font-weight: normal;
}

.projects {
  display: flex;
  flex-direction: column;
  gap: 3rem;
}

.project {
  display: flex;
  align-items: center;
  overflow: hidden;
  gap: 2vw;
  color: black;
  text-decoration: none;
}

.project:not(:last-child) {
  padding-bottom: 3rem;
  border-bottom: solid 1px rgba(192, 192, 192, 0.468)
}

.text {
  flex: 1;
  overflow: hidden;
}

.text > h3 {
  font-size: min(3rem, 8vw);
  margin: 0;

}

.videoPortrait {
  height: 30rem;
  width: auto;
  border-radius: 1rem;
}

@media (width < 50rem) {
  .project {
    flex-direction: column;
  }
  .text > * {
    text-align: center;

  }
  .videoPortrait {
    width: 80%;
    height: auto;
  }
}
