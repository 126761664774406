.container {
  background-color: white;
  border-bottom: 1px solid silver;
  padding: 0 min(3rem, 4vw);

  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;

  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;

  height: var(--nav-menu-height);
}

.section {
  flex: 1;
  display: flex;
  align-items: center;
  column-gap: min(2vw, 1rem);
  padding: 1rem 0;
}

.section > a {
  font-size: min(0.9rem, 3vw);
  text-transform: uppercase;
  text-decoration: none;
  color: black;
  font-family: Chopin;
  font-weight: bolder;
  cursor: pointer;
  transition: opacity 500ms;
}

.section> img {
  height: 2rem;
  width: auto;
  object-fit: contain;
}

.section:nth-child(1) {
  column-gap: clamp(0.5rem, 10%, 3rem);
}

.section:nth-child(2) {
  justify-content: flex-end;
}

.section:nth-child(2) > a {
  transition: scale 300ms;
}

.section:nth-child(2) > a:hover {
  scale: 1.3;
}

.section > button {
  cursor: pointer;
  background-color: transparent;
  border: none;
  padding: 0;
}

@media (width < 50rem) {
  .section:nth-child(2) {
    display: none;
  }
  .section {
    justify-content: center;
  }
}

@media (width < 40rem) {
  .section img {
    display: none;
  }
}