@font-face {
  font-family: Chopin;
  src: url("../fonts/chopin-font-family/Chopin-Trial-Medium-BF65b1d69162573.otf") format("opentype");
}

@font-face {
  font-family: Chopin-Light;
  src: url("../fonts/chopin-font-family/Chopin-Trial-Thin-BF65b1d6921288d.otf") format("opentype");
}

@font-face {
  font-family: Merriweather;
  src: url(../fonts/Merriweather/Merriweather-Regular.ttf);
}