.container {  
  position: absolute;
  left: 0;
  right: 0;
  top: 4.2rem;
  bottom: 0;
  z-index: -1;

  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.container > div {
  max-width: 100%;
}

.container > div > h1 {
  margin: 0;
  padding: 2vh 8vw;
  font-size: min(min(20vh, 10vw), 5.5rem);
  font-weight: normal;
  color: black;

  text-align: center;
  vertical-align: middle;
}

.cursor {
  font-family: Chopin-Light;
  font-weight: bold;
}

.touch {
  height: calc(100vh - var(--nav-menu-height));
  background-color: transparent;
  border: none;
  margin: none;
  padding: 0;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  box-shadow: none;
}

.touch > button {
  padding: 1.5rem 0;
  border: none;
  background: none;
  cursor: pointer;
  transition: opacity 300ms, scale 300ms;
}

.touch > button:hover {
  scale: 1.3;
}

@media (height < 25rem) {
  .touch > button {
    display: none;
  }
}