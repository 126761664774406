footer {
  margin-top: 3rem;
  padding: 2rem 16vw;
  background-color: rgb(222, 222, 222);
}

footer > p {
  color: rgb(81, 81, 81);
  font-style: italic;
}

footer > p > a {
  color: rgb(40, 40, 40);
}
