html, body, #root {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

#root {
  --nav-menu-height: 4.2rem;
  overflow: auto;
  display: flex;
  flex-direction: column;
  scroll-behavior: smooth;
  scrollbar-gutter: stable;
  opacity: 0;
  scale: .5;
}

#root.reveal {
  transition: scale 200ms 100ms, opacity 1000ms 0ms;
  opacity: 1;
  scale: none;
}

#root * {
  flex-shrink: 0;
  user-select: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}