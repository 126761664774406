.container {
  padding: 0 16vw;
  background-color: white;
  z-index: 10;
}
.container > h2 {
  margin: 0;
}
.section {
  display: flex;
  gap: min(5vw, 2rem);
}
.section:nth-child(1) {
  flex: 1;
}
.list {
  flex: 1;
}
.list > h3 {
  font-family: chopin;
  /* text-decoration: underline; */
  font-weight: bolder;
}
.list > a {
  color: black;
  text-decoration: none;
}
.info {
  flex: 3;
  display: flex;
  align-items: center;
}

@media (width < 30rem) {
  .info {
    display: none;
  }
}