#mouse-pointer {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3000;

  background-color: white;
  transform: translate(10%, 10%);
  height: 0;
  width: 0;
  transition: height 600ms, width 600ms, border-radius 600ms;

  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
}

#mouse-pointer > svg {
  height: 0;
  width: 0;
  transition: height 600ms, width 600ms, border-radius 600ms;
}

#mouse-pointer.show {
  border: solid 1px silver;
  height: 4rem;
  width: 4rem;
  border-radius: 50%;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.311);
}

#mouse-pointer.show > svg {
  height: 35%;
  width: 35%;
  color: black;
}