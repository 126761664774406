.container {
  margin: 0 16vw;
  display: flex;
  flex-direction: column;
  gap: 3rem;
}

.container > h1 {
  width: min(100%, 40rem);
  margin: 0 auto;
  text-align: center;

  font-size: min(4rem, 10vw);
  font-weight: normal;
}

.container > div {
  display: flex;
  gap: 1rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;

}

.container > div > img {
  width: min(80%, 20rem);
  height: auto;
}

.container > div > a {
  font-size: 1.5rem;
  color: black;
  text-decoration: none;
  
  padding: 1rem 1.5rem;
  border: solid 1px lightgrey;
  border-radius: 2rem;
  box-shadow: 0 0 1rem lightgrey;
  transition: color 500ms, background-color 500ms, border 500ms;
}

.container > div > a:hover {
  color: white;
  background-color: black;
  border-color: black;
}