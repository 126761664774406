.container {
  padding: 0 16vw;
  background-color: white;
  display: flex;
  gap: min(3vw, 3rem);
  align-items: center;
}

.container > img {
  height: auto;
  width: calc(100% / 2);
  object-fit: contain;
}

.container > div {
  flex: 1;
}

.container > div > h2 {
  font-size: 2rem;
  color: black;
  margin: 0;
}

.container > div > p {
  font-size: clamp(1rem, 4.5vw, 1.3rem);
  color: #202020;
}

.container > div > p > a {
  font-style: italic;
  color: rgb(86, 86, 86);
}

@media (width < 60rem) {
  .container > img {
    width: 80%;
  }

  .container {
    flex-direction: column-reverse;
  }
}